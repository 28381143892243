<template>
  <div>
    <section>
        <button
            v-if="$can('EDIT', 'worker-mitra')"
            class="uk-button uk-button-default uk-border-rounded uk-margin-right"
            style="background: #0ABAB5; border-color: #0ABAB5; color: #ffffff"
            @click="$emit('action', true)"
        >
            Edit
        </button>
    </section>
    <section class="uk-margin">
      <div
        class="uk-grid-collapse"
        style="padding: .2rem 0"
        uk-grid
        v-for="(detail, dKey) in details"
        :key="dKey"
      >
        <div
          v-if="detail.label === 'Note'"
          class="uk-width-1-2"
          style="font-size: 13px; color: #C4C4C4"
        >
          {{ detail.value }}
        </div>
        <div v-else class="uk-width-1-2">{{ changeLabel(detail.label) }}</div>
        
        <div v-if="detail.label !== 'Note'" class="uk-width-1-2">
          <div class="uk-grid-collapse" uk-grid>
            <div class="uk-width-auto">:&nbsp;</div>
            <div class="uk-width-expand">
              {{ detail.value === 'payment_method' ? getRealData(data[detail.value]) : data[detail.value] }}
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            isLoading: false,
            details: [
                { label: 'Tipe Pembayaran', value: 'payment_method' },
                { label: 'Nama Bank', value: 'bank_name' },
                { label: 'Nomor Rekening', value: 'bank_account_number' },
                { label: 'Nama Pemilik Rekening', value: 'bank_account_name' },
                { label: 'Note', value: 'Saya menyatakan bahwa data rekening tersebut sudah benar dan sesuai. Jika ada perubahan saya akan melakukan konfirmasi kepada tim MYROBIN' },
                { label: 'Nomor NPWP', value: 'npwp' },
                { label: 'Nomor BPJS Kesehatan', value: 'bpjs_kes' },
                { label: 'Nomor BPJS Ketenagakerjaan', value: 'bpjs_tk' },
                { label: 'BPJS BPU (Bukan Penerima Upah)', value: 'bpjs_bpu' },
            ],
            data: null,
        };
    },

    async mounted() {
        this.getMitraIdentityDoc();
    },

    methods: {
        ...mapActions({
            getIdentityDoc: 'mitra/getIdentityDoc',
        }),

        getRealData(value) {
            switch (value) {
            case 1:
                return "Bank";
            default:
                return "E-Wallet";
            }
        },

        changeLabel(label) {
            if (this.data.payment_method == 2 && label === 'Nama Bank') {
                return "E-Wallet";
            } else if (this.data.payment_method == 2 && label === 'Nomor Rekening') {
                return "Nomor Handphone";
            } else if (this.data.payment_method == 2 && label === 'Nama Pemilik Rekening') {
                return "Nama Akun E-Wallet";
            } else {
                return label;
            }
        },

        async getMitraIdentityDoc() {
            this.isLoading = true;
            const data = await this.getIdentityDoc(this.$route.params.mitra_id);

            if (data.result) {
                this.data = data.result;
                this.$emit("passData", this.data);
            }

            this.isLoading = false;
        }
    }
};
</script>

<style scoped>
  .avatar {
    width: 100px;
    height: 100px;
    border: 0;
  }
</style>
