<template>
    <div>
        <section>
            <button
                class="uk-button uk-button-default uk-border-rounded uk-margin-right"
                style="background: #0ABAB5; border-color: #0ABAB5; color: #ffffff"
                @click="saveData"
            >Save</button>
            <button
                class="uk-button uk-button-default uk-border-rounded uk-margin-right"
                style="border-color: #0ABAB5; color: #0ABAB5"
                @click="$emit('action', false)"
            >Cancel</button>
        </section>
        <section class="uk-margin">
            <div
                class="uk-grid-collapse"
                style="padding: .2rem 0"
                uk-grid
                v-for="(detail, dKey) in details"
                :key="dKey"
            >
                <div
                    v-if="detail.label === 'Note'"
                    class="uk-width-1-2"
                    style="font-size: 13px; color: #C4C4C4"
                >{{ detail.value }}</div>
                <div v-else class="uk-width-1-2 uk-margin-auto-vertical">
                    {{ changeLabel(detail.label) }}
                </div>

                <div v-if="detail.label !== 'Note'" class="uk-width-1-2">
                    <div class="uk-grid-collapse" uk-grid>
                        <!-- SELECT INPUT -->
                        <div v-if="detail.type === 'select'" class="uk-width-expand">
                        <!-- Normal Select -->
                            <section v-if="detail.value === 'payment_method'">
                                <select v-model="form[detail.value]" class="uk-select" @change="resetForm">
                                    <option v-for="option in payment_method" :key="option._id" :value="option._id">
                                        {{ option.name }}
                                    </option>
                                </select>
                            </section>
                            <section v-else>
                                <select v-model="form[detail.value]" class="uk-select">
                                    <option v-for="option in options[form.payment_method]" :key="option" :value="option">
                                        {{ option }}
                                    </option>
                                </select>
                            </section>
                        </div>
                        <!-- TEXT INPUT -->
                        <div v-else class="uk-width-expand">
                            <input
                                v-model="form[detail.value]"
                                class="uk-input"
                                type="text"
                            >
                        </div>
                    </div>
                    <div v-if="errorsD[detail.value]" class="uk-text-danger">{{ errorsD[detail.value] }}</div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { notificationSuccess } from '@/utils/notification';
import { BANK_LIST, EWALLET_LIST} from '@/utils/constant';

export default {
    props: {
        data: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            isLoading: false,
            payment_method: [
                { name: "Pilih Tipe Pembayaran:", _id: 0 },
                { name: "Bank", _id: 1 },
                { name: "E-Wallet", _id: 2 }
            ],
            options: {
                0: [],
                1: BANK_LIST,
                2: EWALLET_LIST,
            },
            details: [
                { label: 'Tipe Pembayaran', value: 'payment_method', type: "select" },
                { label: 'Nama Bank', value: 'bank_name', type: "select" },
                { label: 'Nomor Rekening', value: 'bank_account_number', type: "text" },
                { label: 'Nama Pemilik Rekening', value: 'bank_account_name', type: "text" },
                { label: 'Note', value: 'Saya menyatakan bahwa data rekening tersebut sudah benar dan sesuai. Jika ada perubahan saya akan melakukan konfirmasi kepada tim MYROBIN' },
                { label: 'Nomor NPWP', value: 'npwp', type: "text" },
                { label: 'Nomor BPJS Kesehatan', value: 'bpjs_kes', type: "text" },
                { label: 'Nomor BPJS Ketenagakerjaan', value: 'bpjs_tk', type: "text" },
                { label: 'BPJS BPU (Bukan Penerima Upah)', value: 'bpjs_bpu', type: "text" },
            ],
            form: { ...this.$props.data },
            errorsD: {},
        };
    },

    methods: {
        ...mapActions({
            patchIdentityDoc: 'mitra/patchIdentityDoc',
        }),

        changeLabel(label) {
            if (this.form.payment_method == 2 && label === 'Nama Bank') {
                return "E-Wallet";
            } else if (this.form.payment_method == 2 && label === 'Nomor Rekening') {
                return "Nomor Handphone";
            } else if (this.form.payment_method == 2 && label === 'Nama Pemilik Rekening') {
                return "Nama Akun E-Wallet";
            } else {
                return label;
            }
        },

        resetForm() {
            this.form.bank_name = null;
            this.form.bank_account_number = null;
            this.form.bank_account_name = null;
        },

        async saveData() {
            this.isLoading = true;

            if (!this.validateData()) {
                this.isLoading = false;
                return false;
            }

            const nForm = {
                "payment_method": this.form.payment_method,
                "bank_name": this.form.bank_name,
                "bank_account_name": this.form.bank_account_name,
                "bank_account_number": this.form.bank_account_number,
                "npwp": this.form.npwp === "" ? null : this.form.npwp,
                "bpjs_kes": this.form.bpjs_kes === "" ? null : this.form.bpjs_kes,
                "bpjs_tk": this.form.bpjs_tk === "" ? null : this.form.bpjs_tk,
                "bpjs_bpu": this.form.bpjs_bpu === "" ? null : this.form.bpjs_bpu
            };

            await this.patchIdentityDoc({ user_id: this.$route.params.mitra_id, payload: nForm });
            notificationSuccess('Update saved!');

            this.isLoading = false;
            this.$emit('action', false);
        },

        validateData() {
            this.errorsD = {};
            let isValidated = true;
            
            if (!this.form.payment_method) {
                this.errorsD["payment_method"] = "tipe pembayaran harus diisi";
                isValidated = false;
            }
            
            if (!this.form.bank_name) {
                this.errorsD["bank_name"] = "bank harus diisi";
                isValidated = false;
            }
            
            if (!this.form.bank_account_name) {
                this.errorsD["bank_account_name"] = "nama pemilik akun bank harus diisi";
                isValidated = false;
            }
            
            if (this.form.bank_account_name && !/^[a-zA-Z ]*$/.test(this.form.bank_account_name)) {
                this.errorsD["bank_account_name"] = "nama pemilik akun bank tidak valid";
                isValidated = false;
            }
            
            if (!this.form.bank_account_number) {
                this.errorsD["bank_account_number"] = "nomor rekening harus diisi";
                isValidated = false;
            }
            
            if (this.form.bank_account_number && !/^\d*$/.test(this.form.bank_account_number)) {
                this.errorsD["bank_account_number"] = "nomor rekening tidak valid";
                isValidated = false;
            }
            
            if (this.form.npwp && !/^\d*$/.test(this.form.npwp)) {
                this.errorsD["npwp"] = "nomor npwp tidak valid";
                isValidated = false;
            }
            
            if (this.form.bpjs_kes && !/^\d*$/.test(this.form.bpjs_kes)) {
                this.errorsD["bpjs_kes"] = "nomor bpjs kesehatan tidak valid";
                isValidated = false;
            }
            
            if (this.form.bpjs_tk && !/^\d*$/.test(this.form.bpjs_tk)) {
                this.errorsD["bpjs_tk"] = "nomor bpjs ketenagakerjaan tidak valid";
                isValidated = false;
            }

            if (this.form.bpjs_bpu && !/^\d*$/.test(this.form.bpjs_bpu)) {
                this.errorsD["bpjs_bpu"] = "bpjs bpu tidak valid";
                isValidated = false;
            }

            return isValidated ? true : false;
        },
    }
};
</script>

<style scoped>
  .avatar {
    width: 100px;
    height: 100px;
    border: 0;
  }
</style>
